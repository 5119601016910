<template>
  <div class="right">
    <span class="spen">我的发票</span>
    <div class="menu">
      <!--      补开发票-->
    </div>
    <Loading v-if="isLoading" />
    <div v-else>
      <div class="tishi" v-if="order_list == ''">{{ tishi }}</div>
      <div class="toptop" v-if="order_list != ''">
        <div class="dingdan">{{ $t('common.Order_details') }}</div>
        <div class="fapiao">{{ $t('label.Invoice_type') }}</div>
        <div class="zhuangtai">状态</div>
        <div class="caozuo">{{ $t('label.Controls') }}</div>
      </div>
      <div v-for="(item, index) in order_list" :key="index">
        <div class="top">
          <div class="biaoti">{{ item.createTime }}</div>
          <div class="biaoti mar">
            {{ $t('label.Order_number') }}：<span class="danhao">{{ item.orderNo }}</span>
          </div>
          <div class="biaoti mar">{{ $t('common.shop') }}：{{ item.storeName }}</div>
        </div>
        <div class="bottom" v-for="(res, k) in item.orderSkuList" :key="k">
          <div class="pic">
            <Uimage :src="res.url" alt="" />
          </div>
          <div class="title1">
            <div class="title">
              <div class="miaoshu">
                {{ res.spuName }}
              </div>
              <div class="shuliang">X{{ res.skuCount }}</div>
            </div>
            <!-- 商品属性 -->
            <div class="shuxing" v-for="(q, c) in res.saleAttrCode" :key="c">
              <div v-if="q.attrName">{{ q.attrName }}：</div>
              <div v-if="q.attrValue" v-html="q.attrValue"></div>
            </div>
          </div>
          <div class="tousu">
            <div
              v-if="
                (item.orderExt.needInv == 1 || item.orderExt.needInv == 2) &&
                  res.isTrue == 'true'
              "
            >
              {{ $t('label.Electronic_ordinary_invoice') }}
            </div>
          </div>

          <div class="zhuangtai">
            <div v-if="item.orderExt.needInv == 0 && res.isTrue == 'true'">
              未开票
            </div>
            <div v-if="item.orderExt.needInv == 1 && res.isTrue == 'true'">
              开票中
            </div>
            <div v-if="item.orderExt.needInv == 2 && res.isTrue == 'true'">
              已开票
            </div>
          </div>
          <div class="pay">
            <div
              class="fapiaoshenqing"
              @click="applyInvoice(item, res)"
              v-if="
                (item.orderExt.needInv == 0 || item.orderExt.needInv == null) &&
                  res.isTrue == 'true'
              "
            >
            {{ $t('button.Apply_for_invoice') }}
            </div>
            <div
              class="fapiaoshenqingzhong"
              v-if="item.orderExt.needInv == 1 && res.isTrue == 'true'"
            >
            {{ $t('common.Invoice_application') }}
            </div>
            <div
              class="fapiaochakan"
              v-if="
                ((item.orderStatus == 3 && item.orderExt.needInv == 2) ||
                  (item.orderStatus == 4 && item.orderExt.needInv == 2)) &&
                  res.isTrue == 'true'
              "
            >
            {{ $t('button.Check_the_invoice') }}
            </div>
          </div>
        </div>
      </div>
      <div class="fenye" v-if="order_list != ''">
        <el-pagination
          @current-change="pagechange"
          :current-page="page.current"
          style="text-align: right;margin-top: 20px"
          background
          :total="page.total"
          :page-size="page.size"
          layout="prev, pager, next, jumper, ->, total"
        ></el-pagination>
      </div>

      <el-dialog
        :title="$t('label.New_invoice_title')"
        :visible.sync="dialogVisible"
        :before-close="handleClose"
        center
      >
        <el-form ref="form" :model="form" :rules="rules" label-width="80px">
          <el-form-item label-width="400px">
            <span slot="label"
              ><span style="color: #f56c6c;font-size: 14px;">
                {{ $t('message.longmessage-012') }}
              </span></span
            >
          </el-form-item>
          <el-form-item
            v-show="false"
            :label="$t('label.Order_number2')"
            label-width="100px"
            prop="orderId"
          >
            <el-input v-model="form.orderId"></el-input>
          </el-form-item>
          <el-form-item :label="$t('label.Invoice_type')" label-width="100px" prop="invType">
            <el-select
              v-model="form.invType"
              :placeholder="$t('label.Invoice_type')"
              :disabled="jin"
            >
              <el-option :label="$t('label.Electronic_ordinary_invoice')" value="0"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('label.Invoice_content')" label-width="100px" prop="region">
            <el-select
              v-model="form.region"
              :placeholder="$t('label.Invoice_content')"
              :disabled="jin"
            >
              <el-option :label="$t('label.detail')" value="mx"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            :label="$t('label.Header_type')"
            label-width="100px"
            prop="ivcTitleType"
          >
            <el-select
              v-model="form.ivcTitleType"
              :placeholder="$t('label.Header_type')"
              :disabled="jin"
            >
              <el-option :label="$t('label.personage')" value="0"></el-option>
              <el-option :label="$t('label.firm')" value="1"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('label.Invoice_title')" label-width="100px" prop="invTitle">
            <el-input v-model="form.invTitle" :disabled="jin"></el-input>
          </el-form-item>
          <el-form-item
            v-if="form.ivcTitleType == '1'"
            :label="$t('label.Duty_number')"
            label-width="100px"
            prop="registCode"
          >
            <el-input v-model="form.registCode" :disabled="jin"></el-input>
          </el-form-item>

          <el-form-item
            v-if="form.ivcTitleType == '1'"
            :label="$t('lable.Bank_of_deposit')"
            label-width="100px"
            prop="registBank"
          >
            <el-input v-model="form.registBank" :disabled="jin"></el-input>
          </el-form-item>
          <el-form-item
            v-if="form.ivcTitleType == '1'"
            :label="$t('lable.Bank_account_number')"
            label-width="100px"
            prop="bankAccount"
          >
            <el-input v-model="form.bankAccount" :disabled="jin"></el-input>
          </el-form-item>
          <el-form-item
            v-if="form.ivcTitleType == '1'"
            :label="$t('lable.Enterprise_address')"
            label-width="100px"
            prop="registAddress"
          >
            <el-input v-model="form.registAddress" :disabled="jin"></el-input>
          </el-form-item>
          <el-form-item
            v-if="form.ivcTitleType == '1'"
            :label="$t('lable.Enterprise_telephone')"
            label-width="100px"
            prop="registPhone"
          >
            <el-input v-model="form.registPhone" :disabled="jin"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">{{ $t('button.Cancel') }}</el-button>
          <el-button type="primary" @click="submitForm('form')"
            >{{ $t('button.Confirm') }}</el-button
          >
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { post, get } from '@/utils/request';
import { getContextData, setContextData } from '../../../utils/session';
export default {
  data() {
    return {
      order_list: [],
      isLoading: true,
      currentPage: 1,
      totalNum: 1,
      page: {
        total: 1,
        size: 10,
        current: 1,
      },
      mes: '',
      tishi: this.$t('common.Its_empty'),
      dialogVisible: false,
      form: {
        orderId: '',
        invType: '',
        region: '',
        ivcTitleType: '',
        invTitle: '',
        registCode: '',
        registBank: '',
        bankAccount: '',
        registAddress: '',
        registPhone: '',
        invId: '',
      },
      jin: true,
      rules: {
        invTitle: [
          { required: true, message: this.$t('message.longmessage-011'), trigger: 'blur' },
        ],
        registCode: [
          { required: true, message: this.$t('message.shortmessage-028'), trigger: 'blur' },
        ],
      },
    };
  },
  created() {
    this.currentPage = getContextData('currentPage') || 1;
    this.getorder(this.currentPage);
  },
  methods: {
    handleClose(done) {
      this.$confirm(this.$t('message.Confirm_closure'))
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    pagechange(p) {
      this.isLoading = true;
      this.page.current = p;
      setContextData('currentPage', this.currentPage);
      get(
        'api/orderbasic/getInvOrderByMemberId?pageNum=' + p + '&pageSize=10'
      ).then((res) => {
        this.isLoading = false;
        res.data.data.list.forEach((req) => {
          if (req.orderSource == 4) {
            req.orderSkuList[0].skuRealPrice = 0;
          }
          req.orderSkuList.forEach((q) => {
            q.saleAttrCode = eval('(' + q.saleAttrCode + ')');
            if (q.value != '0mol/mol' && q.value != '0ppm' && q.value != null) {
              if (q.value.indexOf('mol/mol') != -1) {
                q.saleAttrCode.push({
                  attrName: '确认含量',
                  attrValue: q.value.replace(
                    'mol/mol',
                    'X10<sup>-6</sup>mol/mol'
                  ),
                });
              } else {
                q.saleAttrCode.push({
                  attrName: '确认含量',
                  attrValue: q.value,
                });
              }
            }
            q.saleAttrCode.forEach((k, index) => {
              if (k.attrName == '浓度范围') {
                q.saleAttrCode.splice(index, 1);
              }
            });
            Object.assign(q, { isTrue: 'false' });
          });
          for (let q = 0; q < req.orderSkuList.length; q++) {
            for (let j = q + 1; j < req.orderSkuList.length; j++) {
              if(req.orderSkuList[q].remark =='1'&& req.orderSkuList[j].remark=='1'){
                if (req.orderSkuList[q].skuName == req.orderSkuList[j].skuName&&req.orderSkuList[q].storeId == req.orderSkuList[j].storeId&&req.orderSkuList[q].prodCatId == req.orderSkuList[j].prodCatId&&req.orderSkuList[q].batchNumber == req.orderSkuList[j].batchNumber) {
                  for (
                    let p = 0;
                    p < req.orderSkuList[j].saleAttrCode.length;
                    p++
                  ) {
                    if (
                      req.orderSkuList[j].saleAttrCode[p].attrName == '多组分' ||
                      req.orderSkuList[j].saleAttrCode[p].attrName == '气体组分' ||
                      req.orderSkuList[j].saleAttrCode[p].attrName == '确认含量'
                    ) {
                      req.orderSkuList[q].saleAttrCode.push(
                        req.orderSkuList[j].saleAttrCode[p]
                      );
                    }
                  }
                }
              }
            }
          }
          req.orderSkuList[0].isTrue = 'true';
        });
        res.data.data.list.forEach((i)=>{
          for (let q = 0; q < i.orderSkuList.length; q++) {
            for (let j = q+1; j < i.orderSkuList.length; j++) {
              if(i.orderSkuList[q].remark =='1'&& i.orderSkuList[j].remark=='1'){
                if (i.orderSkuList[q].skuName == i.orderSkuList[j].skuName&&i.orderSkuList[q].storeId == i.orderSkuList[j].storeId&&i.orderSkuList[q].prodCatId == i.orderSkuList[j].prodCatId&&i.orderSkuList[q].batchNumber == i.orderSkuList[j].batchNumber) {
                  i.orderSkuList.splice(j,1);
                }
              }
            }
          }
        })
        res.data.data.list.forEach((i)=>{
          for (let q = 0; q < i.orderSkuList.length; q++) {
            for (let j = q+1; j < i.orderSkuList.length; j++) {
              if(i.orderSkuList[q].remark =='1'&& i.orderSkuList[j].remark=='1'){
                if (i.orderSkuList[q].skuName == i.orderSkuList[j].skuName&&i.orderSkuList[q].storeId == i.orderSkuList[j].storeId&&i.orderSkuList[q].prodCatId == i.orderSkuList[j].prodCatId&&i.orderSkuList[q].batchNumber == i.orderSkuList[j].batchNumber) {
                  i.orderSkuList.splice(j,1);
                }
              }
            }
          }
        })
        res.data.data.list.forEach((i)=>{
          for (let q = 0; q < i.orderSkuList.length; q++) {
            for (let j = q+1; j < i.orderSkuList.length; j++) {
              if(i.orderSkuList[q].remark =='1'&& i.orderSkuList[j].remark=='1'){
                if (i.orderSkuList[q].skuName == i.orderSkuList[j].skuName&&i.orderSkuList[q].storeId == i.orderSkuList[j].storeId&&i.orderSkuList[q].prodCatId == i.orderSkuList[j].prodCatId&&i.orderSkuList[q].batchNumber == i.orderSkuList[j].batchNumber) {
                  i.orderSkuList.splice(j,1);
                }
              }
            }
          }
        })
        this.order_list = res.data.data.list;
      });
    },
    getorder(e) {
      get(
        'api/orderbasic/getInvOrderByMemberId?pageNum=' + e + '&pageSize=10'
      ).then((res) => {
        this.isLoading = false;
        res.data.data.list.forEach((req) => {
          if (req.orderSource == 4) {
            req.orderSkuList[0].skuRealPrice = 0;
          }
          req.orderSkuList.forEach((q) => {
            q.saleAttrCode = eval('(' + q.saleAttrCode + ')');
            if (q.value != '0mol/mol' && q.value != '0ppm' && q.value != null) {
              if (q.value.indexOf('mol/mol') != -1) {
                q.saleAttrCode.push({
                  attrName: '确认含量',
                  attrValue: q.value.replace(
                    'mol/mol',
                    'X10<sup>-6</sup>mol/mol'
                  ),
                });
              } else {
                q.saleAttrCode.push({
                  attrName: '确认含量',
                  attrValue: q.value,
                });
              }
            }
            q.saleAttrCode.forEach((k, index) => {
              if (k.attrName == '浓度范围') {
                q.saleAttrCode.splice(index, 1);
              }
            });
            Object.assign(q, { isTrue: 'false' });
          });
          for (let q = 0; q < req.orderSkuList.length; q++) {
            for (let j = q + 1; j < req.orderSkuList.length; j++) {
              if(req.orderSkuList[q].remark =='1'&& req.orderSkuList[j].remark=='1'){
                if (req.orderSkuList[q].skuName == req.orderSkuList[j].skuName&&req.orderSkuList[q].storeId == req.orderSkuList[j].storeId&&req.orderSkuList[q].prodCatId == req.orderSkuList[j].prodCatId&&req.orderSkuList[q].batchNumber == req.orderSkuList[j].batchNumber) {
                  for (
                    let p = 0;
                    p < req.orderSkuList[j].saleAttrCode.length;
                    p++
                  ) {
                    if (
                      req.orderSkuList[j].saleAttrCode[p].attrName == '多组分' ||
                      req.orderSkuList[j].saleAttrCode[p].attrName == '气体组分' ||
                      req.orderSkuList[j].saleAttrCode[p].attrName == '确认含量'
                    ) {
                      req.orderSkuList[q].saleAttrCode.push(
                        req.orderSkuList[j].saleAttrCode[p]
                      );
                    }
                  }
                }
              }
            }
          }
          req.orderSkuList[0].isTrue = 'true';
        });
        res.data.data.list.forEach((i)=>{
          for (let q = 0; q < i.orderSkuList.length; q++) {
            for (let j = q+1; j < i.orderSkuList.length; j++) {
              if(i.orderSkuList[q].remark =='1'&& i.orderSkuList[j].remark=='1'){
                if (i.orderSkuList[q].skuName == i.orderSkuList[j].skuName&&i.orderSkuList[q].storeId == i.orderSkuList[j].storeId&&i.orderSkuList[q].prodCatId == i.orderSkuList[j].prodCatId&&i.orderSkuList[q].batchNumber == i.orderSkuList[j].batchNumber) {
                  i.orderSkuList.splice(j,1);
                }
              }
            }
          }
        })
        res.data.data.list.forEach((i)=>{
          for (let q = 0; q < i.orderSkuList.length; q++) {
            for (let j = q+1; j < i.orderSkuList.length; j++) {
              if(i.orderSkuList[q].remark =='1'&& i.orderSkuList[j].remark=='1'){
                if (i.orderSkuList[q].skuName == i.orderSkuList[j].skuName&&i.orderSkuList[q].storeId == i.orderSkuList[j].storeId&&i.orderSkuList[q].prodCatId == i.orderSkuList[j].prodCatId&&i.orderSkuList[q].batchNumber == i.orderSkuList[j].batchNumber) {
                  i.orderSkuList.splice(j,1);
                }
              }
            }
          }
        })
        res.data.data.list.forEach((i)=>{
          for (let q = 0; q < i.orderSkuList.length; q++) {
            for (let j = q+1; j < i.orderSkuList.length; j++) {
              if(i.orderSkuList[q].remark =='1'&& i.orderSkuList[j].remark=='1'){
                if (i.orderSkuList[q].skuName == i.orderSkuList[j].skuName&&i.orderSkuList[q].storeId == i.orderSkuList[j].storeId&&i.orderSkuList[q].prodCatId == i.orderSkuList[j].prodCatId&&i.orderSkuList[q].batchNumber == i.orderSkuList[j].batchNumber) {
                  i.orderSkuList.splice(j,1);
                }
              }
            }
          }
        })
        this.order_list = res.data.data.list;
        this.page.total = res.data.data.total;
      });
    },
    // 申请发票
    applyInvoice(item, e) {
      // 1.验证订单发票开具是否已超时(超过30天不可开具发票，反之即可)
      get(
        'api/invoice/checkIsPrintInvoiceByOrderId?orderId=' +
          e.orderId.toString()
      ).then((res) => {
        //订单不超过30天，执行开具发票
        if (res.data.data == false) {
          this.checkInvoice(e);
        } else {
          this.isLoading = false;
          this.$message.warning({
            message: this.$t('message.shortmessage-035'),
          });
        }
      });
    },
    // 验证是否存在会员发票抬头
    checkInvoice(e) {
      // 2.验证是否存在会员发票抬头(若存在则直接开具发票，反之需执行新增发票抬头)
      get('api/invoice/checkIsInvoiceTitleByMemberId').then((res) => {
        // 新增发票抬头
        if (res.data.msg == '' || res.data.msg == null) {
          this.jin = false;
          this.form.orderId = e.orderId;
          this.form.invType = '0';
          this.form.region = 'mx';
          this.form.ivcTitleType = '0';
          this.form.invTitle = '';
          this.form.registCode = '';
          this.form.registBank = '';
          this.form.bankAccount = '';
          this.form.registAddress = '';
          this.form.registPhone = '';
          this.form.invId = '';
          this.dialogVisible = true;
          this.isLoading = false;
        } else {
          // 根据订单状态是否确认收货前申请开票
          this.checkOrderStatus(e.orderId, res.data.msg);
          // get('api/account/getMemberInv').then((res) => {
          //   this.form.orderId = e.orderId;
          //   this.form.invType = res.data.data[0].invType;
          //   this.form.region = 'mx';
          //   this.form.ivcTitleType = res.data.data[0].ivcTitleType;
          //   this.form.invTitle = res.data.data[0].invTitle;
          //   this.form.registCode = res.data.data[0].registCode;
          //   this.form.registBank = res.data.data[0].registBank;
          //   this.form.bankAccount = res.data.data[0].bankAccount;
          //   this.form.registAddress = res.data.data[0].registAddress;
          //   this.form.registPhone = res.data.data[0].registPhone;
          //   this.form.invId = res.data.data[0].invId;
          //   this.dialogVisible = true;
          //   this.isLoading = false;
          // });
        }
      });
    },
    // 保存发票抬头
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          post('api/invoice/saveInvoiceTitle', {
            invType: this.form.invType,
            region: this.form.region,
            ivcTitleType: this.form.ivcTitleType,
            invTitle: this.form.invTitle,
            companyName: this.form.invTitle,
            registCode: this.form.registCode,
            registBank: this.form.registBank,
            bankAccount: this.form.bankAccount,
            registAddress: this.form.registAddress,
            registPhone: this.form.registPhone,
          }).then((res) => {
            this.$message.success({
              message: this.$t('message.shortmessage-036'),
            });
            this.dialogVisible = false;
            // 根据订单状态是否确认收货前申请开票
            this.checkOrderStatus(this.form.orderId, res.data.data);
          });
        } else {
          return false;
        }
      });
    },
    // 打印发票
    printInvoice(orderId, invId) {
      post('api/invoice/printInvoice', {
        invId: invId, // 发票抬头
        orderId: orderId, // 订单id
        invoiceType: '0', // 发票类型
      }).then((res) => {
        this.isLoading = false;
      });
    },
    // 验证订单是否已收货
    checkOrderStatus(orderId, invId) {
      get(
        'api/invoice/checkOrderStatusByOrderId?orderId=' +
          orderId +
          '&invId=' +
          invId
      ).then((res) => {
        // 订单没有收货，需确认收货才会开具发票
        if (res.data.data == false) {
          // 刷新订单列表(显示发票申请中)
          this.getorder(this.currentPage);
          this.$message.success({
            message: '发票申请提交成功',
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.tishi {
  width: 90%;
  text-align: center;
  margin-top: 150px;
  font-size: 24px;
  color: rgb(139, 135, 135);
}
.fenye {
  margin-top: 50px;
  margin-bottom: 20px;
}
.right {
  width: 986px;
  padding-top: 34px;
  padding-left: 27px;
  padding-right: 20px;
  background: #fff;
  .spen {
    font-size: 26px;
    font-weight: 600;
    color: #616161;
  }
  .menu {
    font-size: 16px;
    color: #272727;
    height: 30px;
    line-height: 90px;
  }
  .toptop {
    width: 100%;
    height: 45px;
    line-height: 45px;
    background: #f5f5f5;
    display: flex;
    font-size: 14px;
    color: #565656;
    .dingdan {
      width: 544px;
      text-align: center;
    }
    .fapiao {
      width: 175px;
      text-align: center;
    }
    .zhuangtai {
      width: 130px;
      text-align: center;
    }
    .caozuo {
      flex: 1;
      text-align: center;
    }
  }
  .top {
    width: 100%;
    height: 42px;
    line-height: 42px;
    background: #f5f5f5;
    display: flex;
    margin-top: 30px;
    .biaoti {
      font-size: 16px;
      color: #888;
      margin-left: 20px;
      .danhao {
        color: #666;
        font-weight: 800;
      }
      img {
        width: 23px;
        height: 23px;
        margin-left: 10px;
      }
    }
    .dianpu {
      cursor: pointer;
    }
    .mar {
      margin-left: 40px;
    }
    .kefu {
      display: flex;
      align-items: center;
    }
  }
  .bottom {
    padding-bottom: 20px;
    width: 100%;
    border-bottom: 1px solid #f5f5f5;
    display: flex;
    .pic {
      width: 180px;
      height: 136px;
      margin-top: 40px;

      img {
        width: 100%;
        height: 100%;
      }
    }
    .title1 {
      height: 100%;
      border-right: 1px solid #eaeaea;
      padding-right: 10px;
      width: 345px;
      .title {
        padding-top: 34px;
        display: flex;
        justify-content: space-between;
        .miaoshu {
          color: #333;
          width: 300px;
          height: 40px;
          overflow: hidden;
          margin-left: 10px;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }
        .shuliang {
          margin-left: 30px;
        }
      }
      .xiangsi {
        font-size: 14px;
        color: #9a9a9a;
        margin-top: 10px;
      }
      .shuxing {
        margin-left: 10px;
        font-size: 12px;
        color: #9a9a9a;
        /* margin-top: 10px; */
        display: flex;
      }
    }
    .tousu {
      width: 165px;
      border-right: 1px solid #eaeaea;
      text-align: center;
      padding-top: 34px;
      font-size: 14px;
      color: #a0a0a0;
    }
    .zhuangtai {
      width: 130px;
      padding-top: 34px;
      border-right: 1px solid #eaeaea;
      text-align: center;
      color: #e5852e;
    }
    .pay {
      width: 136px;
      padding-top: 34px;
      border-right: 1px solid #eaeaea;
      font-size: 12px;
      color: #333;
      text-align: center;
      .liji {
        color: #2659ff;
        background: #fff;
        padding: 5px 10px;
        width: 75px;
        height: 15px;
        line-height: 15px;
        border-radius: 5px;
        margin: 0 auto;
        cursor: pointer;
        border: 1px solid #2659ff;
      }
      .liji1 {
        color: #fff;
        background: #ff902a;
        padding: 5px 10px;
        width: 85px;
        border-radius: 5px;
        margin: 0 auto;
        margin-top: 10px;
        cursor: pointer;
      }
      .detail {
        margin-top: 5px;
        cursor: pointer;
      }
      .fapiaoshenqing {
        color: #fff;
        background: #42b983;
        padding: 5px 10px;
        width: 85px;
        border-radius: 5px;
        margin: 0 auto;
        cursor: pointer;
        margin-top: 10px;
      }
      .fapiaoshenqingzhong {
        color: #fff;
        background: #ff902a;
        padding: 5px 10px;
        width: 85px;
        border-radius: 5px;
        margin: 0 auto;
        margin-top: 10px;
        cursor: pointer;
      }
      .fapiaochakan {
        color: #fff;
        background: #1850ff;
        padding: 5px 10px;
        width: 85px;
        border-radius: 5px;
        margin: 0 auto;
        cursor: pointer;
        margin-top: 10px;
      }
    }
  }
}
</style>
